import { BrandIcon, Typography } from '@timberhubcom/forest'

import { useTruckReducer } from '@/providers'

export const TrucksStatusAlert = () => {
  const { numberOfConfirmedTrucks, totalNumberOfTrucks } = useTruckReducer()
  if (totalNumberOfTrucks > 1) {
    return (
      <div className={'flex items-center gap-2 bg-grey-50 p-4'}>
        <BrandIcon size={'m'} icon={'truck'} color={'grey'} />
        <div>
          <Typography variant={'headline_ss_xxs'}>{totalNumberOfTrucks} Trucks in PO</Typography>
          <Typography variant={'body_s'}>{numberOfConfirmedTrucks} confirmed</Typography>
        </div>
      </div>
    )
  }

  return null
}
