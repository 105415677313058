import { zodResolver } from '@hookform/resolvers/zod'
import { BlockContainer, Calendar, Container, Input, Popover, Select, Textarea } from '@timberhubcom/forest'
import { format, getWeek, getYear, parse } from 'date-fns'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { TrucksStatusAlert } from '@/components/common/TrucksStatusAlert'
import { useToggle, useTruckById } from '@/hooks'
import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'
import { generateWeeks } from '@/utils'

const formSchema = z.object({
  preparationWeek: z.string().min(1).optional(),
  preparationDate: z.string().min(1).optional(),
  notes: z.string().optional(),
})

type FormData = z.infer<typeof formSchema>

const weeks = generateWeeks(15, true).map(week => ({ value: week.label, label: week.label }))

const parsedDate = (dateStr: string) => {
  return parse(dateStr, 'dd-MM-yyyy', new Date())
}

type FormExwProps = {
  setIsSubmitting: (val: boolean) => void
}

export const FormExw = React.forwardRef<HTMLFormElement, FormExwProps>(({ setIsSubmitting }, ref) => {
  const { truck_id, readiness_date, notes } = useTruckReducer()
  const { dispatch } = useSidebarReducer()
  const { value: isOpened, setValue: setIsOpened } = useToggle()
  const [selectedWeek, setSelectedWeek] = React.useState(readiness_date?.week ?? '')

  const { control, getValues, handleSubmit } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
    defaultValues: {
      preparationWeek: readiness_date?.week ?? undefined,
      preparationDate: readiness_date?.date ?? undefined,
      notes: notes.find(note => note.source === 'purchase_order_truck')?.note,
    },
  })

  const { updateTruckReadiness } = useTruckById(truck_id)

  const onSubmit = async (data: FormData) => {
    const date = data.preparationDate ? data.preparationDate : data.preparationWeek
    const type = data.preparationDate ? 'date' : 'week'

    try {
      setIsSubmitting(true)
      await updateTruckReadiness({
        preparation: {
          date: date ?? readiness_date.date,
          type,
        },
        internal_notes: data.notes,
      })
      setIsSubmitting(false)
      dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'default' } })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Container gap={12} className={'pb-16'}>
        <TrucksStatusAlert />

        <BlockContainer title={'Enter preparation date'} titleVariant={'headline_ss_xs'}>
          <Controller
            key={'preparationWeek'}
            name={'preparationWeek'}
            control={control}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                {...field}
                label={{ text: 'Readiness week' }}
                options={weeks}
                onValueChange={value => field.onChange(value)}
                heightFit
                hasError={invalid}
                value={selectedWeek}
                key={selectedWeek}
                disabled={getValues().preparationDate !== undefined}
              />
            )}
          />

          <Controller
            key={'preparationDate'}
            name={'preparationDate'}
            control={control}
            render={({ field, fieldState: { invalid } }) => {
              const selectedDate = field.value ? parsedDate(field.value) : new Date()
              return (
                <Popover
                  triggerElement={
                    <div>
                      <Input
                        placeholder={'Select date'}
                        value={field?.value ? format(parsedDate(field.value), 'dd/MM/yyyy') : undefined}
                        label={{ text: 'Readiness date' }}
                        heightFit
                        hasError={invalid}
                      />
                    </div>
                  }
                  open={isOpened}
                  onOpenChange={setIsOpened}>
                  <div className={'w-full'}>
                    <Calendar
                      {...field}
                      showOutsideDays
                      fixedWeeks
                      mode={'single'}
                      classNames={{
                        day_today: '',
                      }}
                      defaultMonth={selectedDate}
                      selected={selectedDate}
                      onSelect={date => {
                        field.onChange(date ? format(new Date(date.getTime()), 'dd-MM-yyyy') : undefined)
                        setIsOpened(false)
                        if (date) {
                          setSelectedWeek(`W${getWeek(date)}, ${getYear(date)}`)
                        }
                      }}
                      className={'w-fit'}
                    />
                  </div>
                </Popover>
              )
            }}
          />

          <Controller
            key={'notes'}
            name={'notes'}
            control={control}
            render={({ field }) => (
              <Textarea {...field} rows={5} label={{ text: 'Additional notes' }} placeholder={'Enter'} heightFit />
            )}
          />
        </BlockContainer>
      </Container>
    </form>
  )
})
