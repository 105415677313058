import { Badge, BrandIcon, Button, cn, Container, Dialog, Icon, Input, Typography } from '@timberhubcom/forest'
import React from 'react'

import { EllipseIcon, PolygonIcon } from '@/assets/icons'
import { StatusEnum } from '@/constants'
import { useTruckById } from '@/hooks'
import { useSidebarReducer, useTruckReducer } from '@/providers'
import { truckStatusVariantMapper } from '@/utils/truckStatusVariantMapper'

import { SidebarSteps } from './SidebarSteps'
import {
  SidebarStepViewFirst,
  SidebarStepViewFourth,
  SidebarStepViewSecond,
  SidebarStepViewThird,
} from './SidebarViews'
import { SidebarDocuments } from './SidebarViews/SidebarDocuments/SidebarDocuments'
import { TruckInfo } from './TruckInfo'

const CancelTruckButton = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const truck = useTruckReducer()
  const id = truck.truck_id
  const [truckID, setTruckID] = React.useState('')
  const { cancelTruck } = useTruckById(id)

  const handleCancel = async () => {
    await cancelTruck()
    setIsOpen(false)
  }

  return (
    <Dialog
      title={''}
      confirm={{
        label: 'Yes, cancel',
        onClick: handleCancel,
        disabled: truckID !== id,
        color: 'secondary',
      }}
      cancelLabel={'No'}
      trigger={
        <Button variant={'primary'} color={'error'} disabled={truck?.fe_status === StatusEnum.CANCELLED}>
          Cancel truck
        </Button>
      }
      open={isOpen}
      onOpenChange={setIsOpen}>
      <Container gap={4} className={'mb-4'}>
        <div className={'mx-auto flex size-10 items-center justify-center rounded-full bg-error-100'}>
          <Icon name={'WarningCircle'} size={24} weight={'fill'} className={'text-error-400'} />
        </div>
        <Container gap={1} className={'text-center'}>
          <Typography variant={'headline_ss_xs'}>Cancel purchase truck</Typography>
          <Typography variant={'body_s'}>Are you sure? This action is permanent and irreversible.</Typography>
          <Typography variant={'body_s'}>
            Type this truck ID to confirm: <strong className={'text-grey-overlay-900'}>{id}</strong>
          </Typography>
        </Container>

        <Input value={truckID} onChange={e => setTruckID(e.target.value)} />
      </Container>
    </Dialog>
  )
}

type SidebarProps = {
  className?: string
}

export const SidebarComponent = ({ className }: SidebarProps) => {
  const truck = useTruckReducer()
  const truckId = truck?.truck_id

  const {
    state: { step, view },
  } = useSidebarReducer()

  const renderDefaultView = () => {
    return (
      <div
        className={cn(
          'sticky bottom-0 left-0 top-0 flex h-screen w-[413px] flex-shrink-0 flex-col bg-white',
          className
        )}>
        <div className={'mb-4 px-6 pt-7'}>
          <div className={'relative grid size-12 place-content-center bg-beech-100 px-3 pb-3.5 pt-2'}>
            <BrandIcon size={'s'} className={'size-6'} />
            <div className={'absolute bottom-1 left-1 flex gap-0.5'}>
              <PolygonIcon />
              <EllipseIcon />
            </div>
          </div>
        </div>
        <div className={'px-6'}>
          <Typography variant={'headline_l'}>Truck {truckId}</Typography>
        </div>
        <div className={'mb-6 px-6'}>
          <Badge variant={truckStatusVariantMapper(truck?.fe_status)} className={'capitalize'}>
            {truck?.fe_status?.replace(/_/gi, ' ')}
          </Badge>
        </div>
        <Container className={'mb-8 px-6'} direction={'horizontal'} gap={1}>
          <a href={truck?.urls.quote} target={'_blank'} rel={'noopener noreferrer'}>
            <Button variant={'primary'} color={'grey'}>
              Go to quote
            </Button>
          </a>
          <CancelTruckButton />
        </Container>
        {!!truck && <TruckInfo data={truck} />}
        <SidebarSteps />
      </div>
    )
  }

  const renderStepView = () => {
    switch (step) {
      case 1: {
        return <SidebarStepViewFirst />
      }
      case 2: {
        return <SidebarStepViewSecond />
      }
      case 3: {
        return <SidebarStepViewThird />
      }
      case 4: {
        return <SidebarStepViewFourth />
      }
    }
  }

  const renderDocumentsView = () => {
    return <SidebarDocuments />
  }

  const renderView = () => {
    switch (view) {
      case 'steps': {
        return renderStepView()
      }
      case 'documents': {
        return renderDocumentsView()
      }
      default:
        return renderDefaultView()
    }
  }

  return renderView()
}
