import { Icon } from '@timberhubcom/forest'
import React from 'react'

import { ServerError } from '@/components'
import { IncotermsEnum, StatusEnum } from '@/constants'
import { useTruckById } from '@/hooks'
import { isConfirmedStatus } from '@/lib/helpers.ts'
import { PurchasedTruck } from '@/models/truck.model.ts'
import { getStatus, sanitizeId } from '@/utils'

export type PurchaseTruckContextType = PurchasedTruck & {
  totalNumberOfTrucks: number
  numberOfConfirmedTrucks: number
  isEXW: boolean
  isReadinessConfirmed: boolean
  allStepsCompleted: boolean
  isConfirmed: boolean
  fe_status: StatusEnum
}

const TruckContext = React.createContext<PurchaseTruckContextType | undefined>(undefined)

type TruckProviderProps = {
  id: string
  children: React.ReactNode
}

export const TruckProvider = ({ id, children }: TruckProviderProps) => {
  const { truck, isTruckLoading, error } = useTruckById(id)

  if (isTruckLoading) {
    return (
      <div className={'grid h-screen w-screen place-items-center'}>
        <Icon name={'CircleNotch'} className={'animate-spin text-primary-500'} size={24} />
      </div>
    )
  }

  if (error) {
    return <ServerError error={error} />
  }

  const truckData = (): PurchaseTruckContextType | undefined => {
    if (!truck) {
      return undefined
    }

    const isEXW = [IncotermsEnum.EXW, IncotermsEnum.FCA].includes(truck.incoterms)

    const truckStatus = getStatus(truck.status, truck.order_truck_status)

    const isReadinessValid = truck.readiness_date?.type === 'date' && !!truck.readiness_date?.date
    const isConfirmed = isConfirmedStatus(truckStatus)

    const statuses = [StatusEnum.PURCHASED, StatusEnum.CONFIRMED]

    return {
      ...truck,
      fe_status: truckStatus,
      truck_id: sanitizeId(truck.truck_id),
      totalNumberOfTrucks: (truck?.related_trucks?.length || 0) + 1,
      numberOfConfirmedTrucks:
        (truck?.related_trucks?.filter(truck => isConfirmedStatus(getStatus(truck.status, truck.order_truck_status)))
          .length || 0) + (isConfirmedStatus(truckStatus) ? 1 : 0),
      isEXW,
      isConfirmed,
      isReadinessConfirmed: statuses.includes(truckStatus) ? isReadinessValid : true,
      allStepsCompleted: !statuses.includes(truckStatus),
    }
  }

  return truck && <TruckContext.Provider value={truckData()}>{children}</TruckContext.Provider>
}

export const useTruckReducer = () => {
  const context = React.useContext(TruckContext)
  if (context === undefined) {
    throw new Error('useTruckReducer must be used within a TruckProvider')
  }
  return context
}
