import { cn } from '@timberhubcom/forest'
import { nanoid } from 'nanoid'
import React from 'react'

type BarProps = {
  className: string
}

const Bar: React.FC<BarProps> = ({ className }) => (
  <div className={cn('my-auto h-2 flex-1 shrink-0 rounded-sm bg-grey-overlay-200 transition-all', className)}></div>
)

// completed - primary-400
// current - beech-300
// next - grey-400
// disabled - grey-overlay-200

export const StepProgressBar = ({ totalSteps = 4, currentStep = 1, className = '', showNextStep = true }) => {
  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1)

  return (
    <section className={'flex items-center gap-1'}>
      {steps.map(stepCount => {
        return (
          <Bar
            key={nanoid()}
            className={cn(className, {
              'bg-primary-400': currentStep > stepCount,
              'h-4 bg-beech-300': currentStep === stepCount,
              'bg-grey-400': currentStep + 1 === stepCount && showNextStep,
            })}
          />
        )
      })}
    </section>
  )
}
