import { Button, Collapsible, Container, Dialog, DocumentCard, IconName, Typography } from '@timberhubcom/forest'
import React from 'react'

import { DeleteDialog } from '@/components/common/DeleteDialog'
import { useTruckById } from '@/hooks'
import { companyDisplayUrl } from '@/lib/helpers.ts'
import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'
import { downloadDocumentURL } from '@/utils/downloadDocumentURL'

const getDocumentIcon = (type?: string): IconName => {
  if (type === 'contract') {
    return 'EnvelopeSimple'
  }
  return 'Files'
}

const showFilename = (url: string) => url.split('/').slice(-1)[0]

const nonDeletableDocuments = new Set([''])
const sendableEmailList = new Set(['contract'])

export const DocumentsComponent = () => {
  const { documents, truck_id, supplier, urls, purchase_order_sent } = useTruckReducer()
  const { dispatch } = useSidebarReducer()

  const [isSendingOpen, setIsSendingOpen] = React.useState(false)

  const { sendDocumentByEmail, isDocumentByEmailSending } = useTruckById(truck_id)

  const getStatusText = (machineName: string) => {
    if (machineName === 'contract' && purchase_order_sent) {
      return 'Uploaded & sent'
    }
    return 'Uploaded'
  }

  return (
    <Collapsible
      isDefaultOpened={documents.length > 0}
      title={'Documents'}
      addonButtonProps={{
        children: 'Add document',
        variant: 'primary',
        color: 'grey',
        onClick: () => dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'documents' } }),
      }}>
      <Container gap={2}>
        {documents.length > 0
          ? documents.map(document => {
              const isNotDeletable = nonDeletableDocuments.has(document.machine_name)
              return (
                <DocumentCard
                  key={document.url}
                  statusText={document.status ?? getStatusText(document.machine_name)}
                  badge={{
                    variant: isNotDeletable ? 'grey' : 'primary',
                  }}
                  title={document.name}
                  url={document.url ?? undefined}
                  fileName={showFilename(document.url ?? document.machine_name)}
                  icon={{
                    name: getDocumentIcon(document.machine_name),
                    className: isNotDeletable ? 'fill-grey-overlay-600 bg-grey-50' : undefined,
                  }}
                  addonEnd={
                    <React.Fragment>
                      {!isNotDeletable && <DeleteDialog id={document.id} title={`Delete ${document.name}`} />}
                      {document.url && (
                        <Button
                          variant={'icon'}
                          icon={'DownloadSimple'}
                          color={'grey'}
                          onClick={() => downloadDocumentURL(document.url ?? '')}
                        />
                      )}
                      {sendableEmailList.has(document.machine_name) && (
                        <Dialog
                          title={`This PO will be sent to ${supplier.company_name}`}
                          confirm={{
                            label: 'Yes, send',
                            color: 'secondary',
                            disabled: isDocumentByEmailSending,
                            onClick: async () => {
                              sendDocumentByEmail({
                                purchased_order_id: truck_id,
                                machine_name: document.machine_name,
                              })
                              setIsSendingOpen(false)
                            },
                          }}
                          cancelLabel={'No, cancel'}
                          trigger={
                            <Button variant={'icon'} icon={'PaperPlaneTilt'} iconWeight={'fill'} color={'grey'} />
                          }
                          className={''}
                          open={isSendingOpen}
                          onOpenChange={setIsSendingOpen}>
                          <div className={'flex flex-col gap-1'}>
                            <div className={'flex items-center gap-1'}>
                              <Typography variant={'headline_ss_xxs'} className={'text-grey-overlay-600'}>
                                Company profile:
                              </Typography>
                              <a
                                href={urls.company}
                                className={'underline'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}>
                                <Typography variant={'body_s'} className={'text-primary-400'}>
                                  {companyDisplayUrl(urls.company)}
                                </Typography>
                              </a>
                            </div>
                            <div className={'flex items-center gap-1'}>
                              <Typography variant={'headline_ss_xxs'} className={'text-grey-overlay-600'}>
                                Email:
                              </Typography>
                              <Typography variant={'body_s'}>{supplier.email}</Typography>
                            </div>
                          </div>
                        </Dialog>
                      )}
                    </React.Fragment>
                  }
                />
              )
            })
          : 'No documents'}
      </Container>
    </Collapsible>
  )
}
