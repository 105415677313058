import { zodResolver } from '@hookform/resolvers/zod'
import { BlockContainer, Calendar, Container, Input, Popover, Select, Textarea } from '@timberhubcom/forest'
import { format, getWeek, getYear, parse } from 'date-fns'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { TrucksStatusAlert } from '@/components/common/TrucksStatusAlert'
import { useToggle, useTruckById } from '@/hooks'
import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'
import { generateWeeks } from '@/utils'

const formSchema = z.object({
  preparationWeek: z.string().min(1).optional(),
  preparationDate: z.string().min(1).optional(),
  loadingWeek: z.string().min(1).optional(),
  loadingDate: z.string().min(1).optional(),
  deliveryWeek: z.string().min(1).optional(),
  deliveryDate: z.string().min(1).optional(),
  notes: z.string().optional(),
})

type FormData = z.infer<typeof formSchema>

const weeks = generateWeeks(15, true).map(week => ({ value: week.label, label: week.label }))

const parsedDate = (dateStr: string) => {
  return parse(dateStr, 'dd-MM-yyyy', new Date())
}

type FormDapProps = {
  setIsSubmitting: (val: boolean) => void
}

export const FormDap = React.forwardRef<HTMLFormElement, FormDapProps>(({ setIsSubmitting }, ref) => {
  const { dispatch } = useSidebarReducer()
  const { truck_id, readiness_date, loading_date, delivery_date, notes } = useTruckReducer()
  const { updateTruckReadiness } = useTruckById(truck_id)
  const { value: isOpenedPreparation, setValue: setIsOpenedPreparation } = useToggle()
  const { value: isOpenedLoading, setValue: setIsOpenedLoading } = useToggle()
  const { value: isOpenedDelivery, setValue: setIsOpenedDelivery } = useToggle()
  const [selectedPreparationWeek, setSelectedPreparationWeek] = React.useState(readiness_date?.week ?? '')
  const [selectedLoadingWeek, setSelectedLoadingWeek] = React.useState(loading_date?.week ?? '')
  const [selectedDeliveryWeek, setSelectedDeliveryWeek] = React.useState(delivery_date?.week ?? '')

  const { control, getValues, handleSubmit } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
    defaultValues: {
      preparationWeek: readiness_date?.week ?? undefined,
      preparationDate: readiness_date?.date ?? undefined,
      loadingWeek: loading_date?.week ?? undefined,
      loadingDate: loading_date?.date ?? undefined,
      deliveryWeek: delivery_date?.week ?? undefined,
      deliveryDate: delivery_date?.date ?? undefined,
      notes: notes.find(note => note.source === 'purchase_order_truck')?.note,
    },
  })

  const onSubmit = async (data: FormData) => {
    const preparationType = data.preparationDate ? 'date' : 'week'
    const preparationDate = data.preparationDate ? data.preparationDate : data.preparationWeek

    const loadingType = data.loadingDate ? 'date' : 'week'
    const loadingDate = data.loadingDate ? data.loadingDate : data.loadingWeek

    const deliveryType = data.deliveryDate ? 'date' : 'week'
    const deliveryDate = data.deliveryDate ? data.deliveryDate : data.deliveryWeek

    try {
      setIsSubmitting(true)
      await updateTruckReadiness({
        ...(preparationDate && { preparation: { type: preparationType, date: preparationDate! } }),
        ...(loadingDate && { loading: { type: loadingType, date: loadingDate! } }),
        ...(deliveryDate && { delivery: { type: deliveryType, date: deliveryDate! } }),
        internal_notes: data.notes,
      })
      setIsSubmitting(false)

      dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'default' } })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Container gap={12} className={'pb-16'}>
        <TrucksStatusAlert />

        <BlockContainer title={'Enter readiness date'} titleVariant={'headline_ss_xs'}>
          <Controller
            key={'preparationWeek'}
            name={'preparationWeek'}
            control={control}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                {...field}
                label={{ text: 'Readiness week' }}
                options={weeks}
                onValueChange={value => field.onChange(value)}
                heightFit
                hasError={invalid}
                value={selectedPreparationWeek}
                key={selectedPreparationWeek}
                disabled={getValues().preparationDate !== undefined}
              />
            )}
          />
          <Controller
            key={'preparationDate'}
            name={'preparationDate'}
            control={control}
            render={({ field, fieldState: { invalid } }) => {
              const selectedDate = field.value ? parsedDate(field.value) : new Date()
              return (
                <Popover
                  triggerElement={
                    <div>
                      <Input
                        placeholder={'Select date'}
                        value={field?.value ? format(parsedDate(field.value), 'dd/MM/yyyy') : undefined}
                        label={{ text: 'Readiness date' }}
                        heightFit
                        hasError={invalid}
                        readOnly
                      />
                    </div>
                  }
                  open={isOpenedPreparation}
                  onOpenChange={setIsOpenedPreparation}>
                  <div className={'w-full'}>
                    <Calendar
                      {...field}
                      showOutsideDays
                      fixedWeeks
                      mode={'single'}
                      classNames={{
                        day_today: '',
                      }}
                      defaultMonth={selectedDate}
                      selected={selectedDate}
                      onSelect={date => {
                        field.onChange(date ? format(new Date(date.getTime()), 'dd-MM-yyyy') : undefined)
                        setIsOpenedPreparation(false)
                        if (date) {
                          setSelectedPreparationWeek(`W${getWeek(date)}, ${getYear(date)}`)
                        }
                      }}
                      required
                      className={'w-fit'}
                    />
                  </div>
                </Popover>
              )
            }}
          />
        </BlockContainer>

        <BlockContainer title={'Enter loading date'} titleVariant={'headline_ss_xs'}>
          <Controller
            key={'loadingWeek'}
            name={'loadingWeek'}
            control={control}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                {...field}
                label={{ text: 'Loading week' }}
                options={weeks}
                onValueChange={value => field.onChange(value)}
                heightFit
                hasError={invalid}
                value={selectedLoadingWeek}
                key={selectedLoadingWeek}
                disabled={getValues().loadingDate !== undefined}
              />
            )}
          />
          <Controller
            key={'loadingDate'}
            name={'loadingDate'}
            control={control}
            render={({ field, fieldState: { invalid } }) => {
              const selectedDate = field.value ? parsedDate(field.value) : new Date()
              return (
                <Popover
                  triggerElement={
                    <div>
                      <Input
                        placeholder={'Select date'}
                        value={field?.value ? format(parsedDate(field.value), 'dd/MM/yyyy') : undefined}
                        label={{ text: 'Loading date' }}
                        heightFit
                        hasError={invalid}
                        readOnly
                      />
                    </div>
                  }
                  open={isOpenedLoading}
                  onOpenChange={setIsOpenedLoading}>
                  <div className={'w-full'}>
                    <Calendar
                      {...field}
                      showOutsideDays
                      fixedWeeks
                      mode={'single'}
                      classNames={{
                        day_today: '',
                      }}
                      defaultMonth={selectedDate}
                      selected={selectedDate}
                      onSelect={date => {
                        field.onChange(date ? format(new Date(date.getTime()), 'dd-MM-yyyy') : undefined)
                        setIsOpenedLoading(false)
                        if (date) {
                          setSelectedLoadingWeek(`W${getWeek(date)}, ${getYear(date)}`)
                        }
                      }}
                      required
                      className={'w-fit'}
                    />
                  </div>
                </Popover>
              )
            }}
          />
        </BlockContainer>

        <BlockContainer title={'Update delivery'} titleVariant={'headline_ss_xs'}>
          <Controller
            key={'deliveryWeek'}
            name={'deliveryWeek'}
            control={control}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                {...field}
                label={{ text: 'Delivery week' }}
                options={weeks}
                onValueChange={value => field.onChange(value)}
                heightFit
                hasError={invalid}
                value={selectedDeliveryWeek}
                key={selectedDeliveryWeek}
                disabled={getValues().deliveryDate !== undefined}
              />
            )}
          />
          <Controller
            key={'deliveryDate'}
            name={'deliveryDate'}
            control={control}
            render={({ field, fieldState: { invalid } }) => {
              const selectedDate = field.value ? parsedDate(field.value) : new Date()
              return (
                <Popover
                  triggerElement={
                    <div>
                      <Input
                        placeholder={'Select date'}
                        value={field?.value ? format(parsedDate(field.value), 'dd/MM/yyyy') : undefined}
                        label={{ text: 'Delivery date' }}
                        heightFit
                        hasError={invalid}
                        readOnly
                      />
                    </div>
                  }
                  open={isOpenedDelivery}
                  onOpenChange={setIsOpenedDelivery}>
                  <div className={'w-full'}>
                    <Calendar
                      {...field}
                      showOutsideDays
                      fixedWeeks
                      mode={'single'}
                      classNames={{
                        day_today: '',
                      }}
                      defaultMonth={selectedDate}
                      selected={selectedDate}
                      onSelect={date => {
                        field.onChange(date ? format(new Date(date.getTime()), 'dd-MM-yyyy') : undefined)
                        setIsOpenedDelivery(false)
                        if (date) {
                          setSelectedDeliveryWeek(`W${getWeek(date)}, ${getYear(date)}`)
                        }
                      }}
                      required
                      className={'w-fit'}
                    />
                  </div>
                </Popover>
              )
            }}
          />
        </BlockContainer>

        <BlockContainer title={'Add additional notes'} titleVariant={'headline_ss_xs'}>
          <Controller
            key={'notes'}
            name={'notes'}
            control={control}
            render={({ field }) => (
              <Textarea {...field} rows={5} label={{ text: 'Additional notes' }} placeholder={'Enter'} heightFit />
            )}
          />
        </BlockContainer>
      </Container>
    </form>
  )
})
