import { OrderTruckStatusEnum, PurchaseTruckStatusEnum, StatusEnum } from '@/constants'

export const getStatus = (status: PurchaseTruckStatusEnum, orderTruckStatus?: OrderTruckStatusEnum) => {
  const isCancelled = status === PurchaseTruckStatusEnum.CANCELLED
  const isConfirmed = status === PurchaseTruckStatusEnum.CONFIRMED
  if (isCancelled) {
    return StatusEnum.CANCELLED
  }

  if (orderTruckStatus) {
    if (orderTruckStatus === OrderTruckStatusEnum.PREBOOKED) {
      return StatusEnum.PREBOOKED
    }

    if (orderTruckStatus === OrderTruckStatusEnum.BOOKED) {
      return StatusEnum.BOOKED
    }

    if (orderTruckStatus === OrderTruckStatusEnum.COLLECTED) {
      return StatusEnum.COLLECTED
    }

    if (orderTruckStatus === OrderTruckStatusEnum.DELIVERED) {
      return StatusEnum.DELIVERED
    }
  }

  // If order truck is not found or pending or cancelled, we proceed with the purchase truck statuses.
  if (isConfirmed) {
    return StatusEnum.CONFIRMED
  }

  return StatusEnum.PURCHASED
}
