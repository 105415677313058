import { IconName, IconWeight, InfoCardGroup } from '@timberhubcom/forest'
import React from 'react'

import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'

export const OverviewComponent = () => {
  const data = useTruckReducer()
  const { dispatch } = useSidebarReducer()

  const items = [
    {
      label: 'From supplier',
      value: data.loading_address?.company_name ?? '-',
      details: [
        {
          label: `${data.incoterms} from:`,
          value: data.loading_address.description ?? '-',
          icon: {
            name: 'Buildings' as IconName,
            weight: 'fill' as IconWeight,
            className: 'fill-primary-400',
            size: 20,
          },
        },
      ],
    },
    {
      label: 'To customer',
      value: data.delivery_address?.company_name ?? 'N/A',
      details: [
        {
          label: `${data.buyer_incoterms} to:`,
          value: data.delivery_address?.description ?? 'N/A',
          icon: {
            name: 'Buildings' as IconName,
            weight: 'fill' as IconWeight,
            className: 'fill-grey-overlay-600',
            size: 20,
          },
        },
      ],
    },
    {
      label: 'Supplier PO week',
      value: `Week ${data.purchase_order_week}`,
      details: [
        {
          label: 'Cust. Proforma:',
          value: data.customer_proforma,
          // value: data.incoterms === 'EXW' ? `${data.customer_proforma}` : `${data.delivery_week.date}`,
          icon: {
            name: 'Handshake' as IconName,
            weight: 'fill' as IconWeight,
            className: 'fill-grey-overlay-600',
            size: 20,
          },
        },
      ],
    },
  ]

  React.useEffect(() => {
    dispatch({ type: SidebarActionsEnum.RESET })
  }, [data.truck_id])

  return (
    <div className={'w-full'}>
      <InfoCardGroup items={items} className={'flex-row'} />
    </div>
  )
}
