import { Button, Icon, Typography } from '@timberhubcom/forest'

import { useTruckById } from '@/hooks'
import { useTruckReducer } from '@/providers'

export const ReviewAndSendPO = () => {
  const { urls, supplier, documents, truck_id, purchase_order_sent, totalNumberOfTrucks } = useTruckReducer()

  const isPOExist = !!documents.find(item => item.machine_name === 'contract')?.url
  const { sendDocumentByEmail, isDocumentByEmailSending } = useTruckById(truck_id)

  const submitDocument = async () => {
    await sendDocumentByEmail({ purchased_order_id: truck_id, machine_name: 'contract' })
  }

  return (
    <div>
      {isPOExist && (
        <div>
          {/* start email to send */}
          {!purchase_order_sent && (
            <div>
              <div className={'mb-12 bg-grey-50 p-4'}>
                <div className={'flex'}>
                  <div className={'pr-2'}>
                    <Icon name={'UserCircle'} color={'#a3a3a3'} weight={'fill'} size={24} />
                  </div>
                  <div>
                    <Typography variant={'headline_ss_xxs'}>This PO will be sent to {supplier.company_name}</Typography>
                    <div className={'flex items-center gap-1'}>
                      <Typography variant={'headline_ss_xxs'} className={'text-grey-overlay-600'}>
                        Company profile:
                      </Typography>
                      <a href={urls.company} className={'underline'}>
                        <Typography variant={'body_s'} className={'text-primary-400'}>
                          {supplier.company_name}
                        </Typography>
                      </a>
                    </div>
                    <div className={'flex items-center gap-1'}>
                      <Typography variant={'headline_ss_xxs'} className={'text-grey-overlay-600'}>
                        Email:
                      </Typography>
                      <Typography variant={'body_s'}>{supplier.email}</Typography>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                variant={'primary'}
                isLoading={isDocumentByEmailSending}
                color={'secondary'}
                className={'w-full'}
                onClick={submitDocument}>
                Confirm & send PO for all trucks
              </Button>
            </div>
          )}
          {/* end email to send */}
          {/* start email sent */}
          {purchase_order_sent && (
            <div className={'bg-beech-100 p-4'}>
              <div className={'flex'}>
                <div className={'pr-2'}>
                  <Icon name={'CheckCircle'} color={'#005239'} size={24} weight={'fill'} />
                </div>
                <div>
                  <Typography variant={'headline_ss_xxs'}>
                    Email sent for {totalNumberOfTrucks > 1 ? `${totalNumberOfTrucks} trucks` : '1 truck'}
                  </Typography>
                  <div className={'flex items-center gap-1'}>
                    <Typography variant={'headline_ss_xxs'} className={'text-grey-overlay-600'}>
                      Company profile:
                    </Typography>
                    <a href={urls.company} className={'underline'}>
                      <Typography variant={'body_s'} className={'text-primary-400'}>
                        {supplier.company_name}
                      </Typography>
                    </a>
                  </div>
                  <div className={'flex items-center gap-1'}>
                    <Typography variant={'headline_ss_xxs'} className={'text-grey-overlay-600'}>
                      Email:
                    </Typography>
                    <Typography variant={'body_s'}>{supplier.email}</Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* end email sent */}
        </div>
      )}
    </div>
  )
}
