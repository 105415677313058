import React from 'react'
import { useToggle } from '@/hooks'
import { format, getWeek, getYear, parse } from 'date-fns'
import { Button, Calendar, Container, Input, Popover, Select, Typography } from '@timberhubcom/forest'
import { toLocalISOString } from '@/lib/helpers.ts'
import { generateWeeks } from '@/utils'

type DateEditorType = 'week' | 'date'
type DateEditorProps = {
  title: string
  defaultDate?: string
  defaultWeek?: string
  weekLabel?: string
  dateLabel?: string
  isSubmitting?: boolean
  onSubmit?: (data: { date: string; type: DateEditorType }) => void
}

const weekOptions = generateWeeks(15, true).map(week => ({ value: week.label, label: week.label }))

export function DateEditor({
  title,
  defaultDate,
  defaultWeek,
  weekLabel,
  dateLabel,
  onSubmit,
  isSubmitting,
}: DateEditorProps) {
  const { value: isOpen, setValue: setIsOpen, toggle: toggleTour } = useToggle()
  const { value: isOpened, setValue: setIsOpened } = useToggle()
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(() =>
    defaultDate ? parse(defaultDate, 'dd-MM-yyyy', new Date()) : undefined
  )
  const [selectedWeek, setSelectedWeek] = React.useState(defaultWeek ?? weekOptions[0]?.value)

  const handleDayPickerSelect = (date?: Date) => {
    if (date) {
      setSelectedDate(date)
      setIsOpened(false)
    } else {
      setSelectedDate(undefined)
    }
  }

  return (
    <Popover
      buttonProps={{
        variant: 'icon',
        icon: 'PencilSimple',
        iconWeight: 'fill',
        size: 'xs',
        color: 'secondary',
        className: 'flex-shrink-0',
      }}
      open={isOpen}
      onOpenChange={setIsOpen}
      className={'min-w-80'}>
      <Container className={'w-full'} gap={11}>
        <div className={'flex flex-col gap-y-4'}>
          <Typography variant={'headline_m'}>{title}</Typography>
          <Select
            options={weekOptions}
            onValueChange={val => setSelectedWeek(val)}
            value={selectedWeek}
            key={selectedWeek}
            label={{ text: weekLabel ?? '' }}
            heightFit
            contentClassName={'z-1000'}
            disabled={selectedDate !== undefined}
          />
          <Popover
            triggerElement={
              <div>
                <Input
                  placeholder={'Select date'}
                  value={selectedDate ? format(selectedDate, 'dd/MM/yyyy') : ''}
                  label={{ text: dateLabel ?? '' }}
                  heightFit
                />
              </div>
            }
            open={isOpened}
            onOpenChange={setIsOpened}>
            <div className={'w-full'}>
              <Calendar
                showOutsideDays
                fixedWeeks
                // showWeekNumber
                classNames={{
                  day_today: '',
                }}
                mode={'single'}
                defaultMonth={selectedDate}
                selected={selectedDate}
                onSelect={date => {
                  handleDayPickerSelect(date)
                  if (date) {
                    setSelectedWeek(`W${getWeek(date)}, ${getYear(date)}`)
                  }
                }}
                footer={
                  <p>
                    {selectedDate !== undefined && <p className={'caption'}>Selected: {selectedDate.toDateString()}</p>}
                  </p>
                }
                required
                className={'w-fit'}
              />
            </div>
          </Popover>
        </div>
        <Container gap={2} direction={'horizontal'}>
          <Button className={'w-full'} color={'grey'} variant={'primary'} onClick={toggleTour} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            className={'w-full'}
            color={'secondary'}
            variant={'primary'}
            isLoading={isSubmitting}
            onClick={async () => {
              await onSubmit?.({
                date: selectedDate ? toLocalISOString(selectedDate) : selectedWeek,
                type: selectedDate ? 'date' : 'week',
              })
              setIsOpen(false)
            }}>
            Apply
          </Button>
        </Container>
      </Container>
    </Popover>
  )
}
