import React from 'react'

import { Step } from '@/components/Sidebar/SidebarSteps'
import { isConfirmedStatus } from '@/lib/helpers.ts'
import { PurchaseTruckContextType, useTruckReducer } from '@/providers/TruckProvider.tsx'

export enum SidebarActionsEnum {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  UPDATE = 'UPDATE',
  RESET = 'RESET',
}

type SidebarProviderProps = {
  children: React.ReactNode
}

interface SidebarState {
  step: Step
  view: 'default' | 'documents' | 'steps'
  isConfirmed: boolean
}

type SidebarStepActions = {
  type: SidebarActionsEnum.NEXT | SidebarActionsEnum.PREVIOUS | SidebarActionsEnum.RESET
}

type SidebarUpdateActions = {
  type: SidebarActionsEnum.UPDATE
  payload: Partial<SidebarState>
}

type SidebarActions = SidebarStepActions | SidebarUpdateActions

const initialState: SidebarState = {
  step: 1,
  view: 'default',
  isConfirmed: false,
}

const SidebarContext = React.createContext<{ state: SidebarState; dispatch: React.Dispatch<SidebarActions> }>({
  state: initialState,
  dispatch: () => null,
})

// TODO: Will remove step 3 and 4 until they are ready
function sidebarReducer(state: SidebarState, action: SidebarActions, truck: PurchaseTruckContextType): SidebarState {
  switch (action.type) {
    case SidebarActionsEnum.NEXT: {
      // const getStep = () => {
      //   if (state.step === 1) {
      //     return 2
      //   }
      //   if (state.step === 2) {
      //     return 3
      //   }
      //
      //   return 4
      // }

      return { ...state, step: 2 }
    }
    case SidebarActionsEnum.PREVIOUS: {
      // const getStep = () => {
      //   if (state.isConfirmed || state.step === 2) {
      //     return 2
      //   } else if (state.step === 1) {
      //     return 1
      //   }
      //   if (state.step === 3) {
      //     return 2
      //   }
      //   return 3
      // }

      return { ...state, step: 1 }
    }
    case SidebarActionsEnum.UPDATE: {
      return { ...state, ...action.payload }
    }
    case SidebarActionsEnum.RESET: {
      const { fe_status } = truck
      const isConfirmed = isConfirmedStatus(fe_status)

      return { step: isConfirmed ? 2 : 1, view: 'default', isConfirmed }
    }
    default: {
      return state
    }
  }
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const truck = useTruckReducer()

  const [state, dispatch] = React.useReducer(
    (prevState: SidebarState, action: SidebarActions) => sidebarReducer(prevState, action, truck),
    {
      step: 1,
      view: 'default',
      isConfirmed: false,
    }
  )

  const value = React.useMemo(() => ({ state, dispatch }), [state])

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}

export const useSidebarReducer = () => {
  const context = React.useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebarReducer must be used within a SidebarProvider')
  }
  return context
}
