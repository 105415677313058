import { axiosInstance } from '@/lib/axios.ts'
import { PurchasedTruck, UpdateReadiness } from '@/models/truck.model.ts'

const baseAPI = '/api/v1/it/purchase-order-trucks'

export type PatchTruckPayload = {
  tour_number: string
  supplier_reference_number: string
  remarks: string
  preparation_week: string
  preparation_date: {
    type: 'date' | 'week'
    date: string
  }
  loading_week: string
  loading_date: {
    type: 'date' | 'week'
    date: string
  }
  internal_notes: string
  external_notes: string
  delivery_date: {
    type: 'date' | 'week'
    date: string
  }
}

export const getTrucks = (): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.get(baseAPI)
}

export const getTruckById = (id: string): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.get(`${baseAPI}/${id}`)
}

export const updateTruckById = (id: string, data: Partial<PatchTruckPayload>): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.patch(`${baseAPI}/${id}`, data)
}

export const updateReadiness = async (id: string, body: UpdateReadiness): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.post(`${baseAPI}/${id}/update-readiness`, body)
}

export const deleteDocument = async (id: string, documentId: string): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.delete(`${baseAPI}/${id}/documents/${documentId}`)
}

export const generatePurchaseOrderDocumentApi = async (
  id: string,
  remarks: string
): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.post(`${baseAPI}/${id}/generate-purchase-order`, { remarks })
}

// export const uploadPurchasedOrder = (id: string, data: FormData) => {
export const uploadDocuments = (id: string, data: FormData) => {
  return axiosInstance.post(`${baseAPI}/${id}/documents`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const sendEmail = (purchased_order_id: string, machine_name: string): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.post(`${baseAPI}/${purchased_order_id}/send-document`, { machine_name })
}

export const confirm = (id: string, trucks: string): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.post(`${baseAPI}/${id}/confirm`, { trucks })
}
export const cancelTruckService = (id: string): Promise<{ data: PurchasedTruck }> => {
  return axiosInstance.get(`${baseAPI}/${id}/cancel`)
}
