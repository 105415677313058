import { Button, ButtonProps, Container, Input, Popover, Separator, Typography } from '@timberhubcom/forest'
import React from 'react'

import { useToggle, useTruckById } from '@/hooks'
import { PurchasedTruck } from '@/models/truck.model'

type DetailsProps = {
  title: string
  value: string | number
  footer?: React.ReactNode
  buttonProps?: ButtonProps
}
const Details = ({ title, value, footer, buttonProps }: DetailsProps) => {
  return (
    <div className={'flex items-center justify-between px-6 py-4'}>
      <Container gap={0} className={'gap-y-10'}>
        <Typography variant={'caption'}>{title}</Typography>
        <Typography variant={'headline_ss_xs'}>{value ?? '-'}</Typography>
      </Container>
      {buttonProps ? <Button {...buttonProps} /> : footer}
    </div>
  )
}

type TruckInfoProps = {
  data: PurchasedTruck
}

export const TruckInfo = ({ data }: TruckInfoProps) => {
  const { value: isTourOpen, setValue: setIsTourOpen, toggle: toggleTour } = useToggle()
  const { value: isSupplierOpen, setValue: setIsSupplierOpen, toggle: toggleSupplier } = useToggle()

  const [tourNumber, setTourNumber] = React.useState(data.tour_number ?? '')
  const [supplierContract, setSupplierContract] = React.useState(data.supplier_reference_number ?? '')

  const { updateTruck, isUpdatingTruck } = useTruckById(data.truck_id)

  const onSubmit = async () => {
    await updateTruck({ supplier_reference_number: supplierContract, tour_number: tourNumber })
    setIsTourOpen(false)
    setIsSupplierOpen(false)
  }

  return (
    <Container gap={0} className={'w-full pb-12 pt-6'}>
      <Separator />
      <Details
        title={'Supplier contract'}
        value={data.supplier_reference_number ?? '-'}
        footer={
          <Popover
            buttonProps={{
              variant: 'icon',
              icon: 'PencilSimple',
              iconWeight: 'fill',
              size: 'xs',
              color: 'grey',
            }}
            open={isSupplierOpen}
            onOpenChange={setIsSupplierOpen}>
            <div className={'w-full gap-y-12'}>
              <Input
                label={{ text: 'Supplier contract' }}
                onChange={e => setSupplierContract(e.target.value)}
                value={supplierContract}
              />
              <Container gap={2} direction={'horizontal'}>
                <Button
                  className={'w-full'}
                  color={'grey'}
                  variant={'primary'}
                  onClick={toggleSupplier}
                  disabled={isUpdatingTruck}>
                  Cancel
                </Button>
                <Button
                  className={'w-full'}
                  color={'secondary'}
                  variant={'primary'}
                  isLoading={isUpdatingTruck}
                  onClick={onSubmit}>
                  Apply
                </Button>
              </Container>
            </div>
          </Popover>
        }
      />
      <Separator />
      <Details
        title={'Tour number'}
        value={data.tour_number}
        footer={
          <Popover
            buttonProps={{
              variant: 'icon',
              icon: 'PencilSimple',
              iconWeight: 'fill',
              size: 'xs',
              color: 'grey',
            }}
            open={isTourOpen}
            onOpenChange={setIsTourOpen}>
            <div className={'w-full gap-y-12'}>
              <Input label={{ text: 'Tour number' }} onChange={e => setTourNumber(e.target.value)} value={tourNumber} />
              <Container gap={2} direction={'horizontal'}>
                <Button
                  className={'w-full'}
                  color={'grey'}
                  variant={'primary'}
                  onClick={toggleTour}
                  disabled={isUpdatingTruck}>
                  Cancel
                </Button>
                <Button
                  className={'w-full'}
                  color={'secondary'}
                  variant={'primary'}
                  isLoading={isUpdatingTruck}
                  onClick={onSubmit}>
                  Apply
                </Button>
              </Container>
            </div>
          </Popover>
        }
      />
      <Separator />
      <Details
        title={'Buyer reference'}
        value={data.buyer_reference_number}
        footer={
          <a href={data.urls.truck} target={'_blank'} rel={'noopener noreferrer'}>
            <Button variant={'tertiary'} icon={'ArrowRight'} size={'xs'} disabled={true}>
              Sold truck
            </Button>
          </a>
        }
      />
      <Separator />
    </Container>
  )
}
