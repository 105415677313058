export const documentTypeOptions = (hasOrderTruck = false) => [
  {
    label: 'Purchase order',
    value: 'contract',
  },

  {
    label: 'Supplier packing list',
    value: 'supplier_packing_list',
  },
  {
    label: 'Supplier proforma',
    value: 'supplier_proforma',
  },
  ...(hasOrderTruck
    ? [
        {
          label: 'ISPM certification',
          value: 'ispm_certification',
        },
        {
          label: 'Signed CMR',
          value: 'buyer_cmr',
        },
      ]
    : []),
  {
    label: 'Other',
    value: 'other_document',
  },
]
