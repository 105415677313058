import { Button, Container, DocumentCard, Input, Select, TabsSwitch, Typography } from '@timberhubcom/forest'
import { nanoid } from 'nanoid'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { DeleteDialog } from '@/components/common/DeleteDialog'
import { useTruckById } from '@/hooks'
import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'
import { downloadDocumentURL } from '@/utils/downloadDocumentURL'

import { SidebarWrapper } from '../../SidebarWrapper'
import { SidebarDocumentUploadView } from './components/SidebarDocumentUploadView'
import { SidebarDocumentsFormData } from './sidebardocumentForm'
import { documentTypeOptions } from './sidebarDocumentTypeOptions'

const tabSwitchData = {
  left: {
    text: 'Upload PO',
    value: 'upload',
  },
  right: {
    text: 'Generate PO',
    value: 'generate',
  },
}

export const SidebarDocuments = () => {
  const { dispatch } = useSidebarReducer()

  const { documents, truck_id, has_order_truck } = useTruckReducer()

  const { uploadDocument, isDocumentUploading } = useTruckById(truck_id)
  const { generatePurchaseOrder, isGeneratingPurchaseOrder } = useTruckById(truck_id)

  const methods = useForm<SidebarDocumentsFormData>({
    defaultValues: {
      documentName: '',
      documentType: null,
      remarks: '',
      documentView: 'upload',
      documents: [],
    },
  })
  const { control, watch, resetField, reset } = methods

  const documentType = watch('documentType')
  const document = watch('documents')
  const isPO = documentType === 'contract'
  const isOthers = documentType === 'other_document'
  const isSupplierProforma = documentType === 'supplier_proforma'

  const existingDocument = documents.find(item => item.machine_name === documentType)
  const supplierProformas = documents.filter(item => item.machine_name === 'supplier_proforma')
  const isPOExist = existingDocument?.url

  const generatePO = async () => {
    await generatePurchaseOrder({ id: truck_id, remarks: watch('remarks') ?? '' })
  }

  const onSubmit = async () => {
    if (document && documentType) {
      const machineName = documentType
      await uploadDocument({
        id: truck_id,
        files: [...document],
        machineName,
        ...(isOthers
          ? {
              name: watch('documentName') ?? 'Other document',
            }
          : {}),
      })
      reset()
    }
  }

  const DOCUMENT_TYPE_OPTIONS = documentTypeOptions(has_order_truck)

  const renderContent = () => {
    if (!documentType) {
      return null
    }

    if (isSupplierProforma) {
      return (
        <Container gap={6}>
          <Typography variant={'headline_ss_s'}>Add document</Typography>
          <Container gap={1}>
            {supplierProformas.map(supplierProforma => (
              <DocumentCard
                key={nanoid()}
                addonEnd={
                  <React.Fragment>
                    <DeleteDialog id={supplierProforma.id} title={`Delete ${supplierProforma.name}`} />
                    <Button
                      color={'grey'}
                      icon={'DownloadSimple'}
                      iconWeight={'fill'}
                      variant={'icon'}
                      onClick={() => downloadDocumentURL(supplierProforma.url ?? '')}
                    />
                  </React.Fragment>
                }
                title={supplierProforma.name}
                icon={{
                  className: 'bg-shade fill-primary-400',
                  name: 'Files',
                }}
                fileName={supplierProforma.url?.split('/').slice(-1)[0] ?? supplierProforma.name}
              />
            ))}
          </Container>
          {document.length > 0 && supplierProformas.length > 0 && (
            <Typography variant={'headline_ss_xxs'}>New document(s)</Typography>
          )}
          <SidebarDocumentUploadView
            isUploadView={watch('documentView') === 'upload'}
            isGenerating={isGeneratingPurchaseOrder}
            onGenerate={generatePO}
          />
        </Container>
      )
    }

    return (
      <Container gap={6}>
        <Typography variant={'headline_ss_s'}>Add document</Typography>
        {isPOExist && !isOthers ? (
          <React.Fragment>
            <DocumentCard
              addonEnd={
                <React.Fragment>
                  <DeleteDialog id={existingDocument.id} title={`Delete ${existingDocument.name}`} />
                  <Button
                    color={'grey'}
                    icon={'DownloadSimple'}
                    iconWeight={'fill'}
                    variant={'icon'}
                    onClick={() => downloadDocumentURL(existingDocument.url ?? '')}
                  />
                </React.Fragment>
              }
              title={existingDocument.name}
              icon={{
                className: 'bg-shade fill-primary-400',
                name: 'Files',
              }}
              fileName={existingDocument.url?.split('/').slice(-1)[0] ?? existingDocument.name}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {isPO && (
              <Controller
                key={'documentView'}
                name={'documentView'}
                control={control}
                render={({ field }) => <TabsSwitch {...field} left={tabSwitchData.left} right={tabSwitchData.right} />}
              />
            )}
            <SidebarDocumentUploadView
              isUploadView={watch('documentView') === 'upload'}
              isGenerating={isGeneratingPurchaseOrder}
              onGenerate={generatePO}
            />
          </React.Fragment>
        )}
      </Container>
    )
  }

  return (
    <SidebarWrapper
      title={'Add a document'}
      primaryCTA={{
        label: 'Cancel',
        props: {
          color: 'grey',
          onClick: () => dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'default' } }),
          disabled: isDocumentUploading,
        },
      }}
      secondaryCTA={{
        label: 'Add',
        props: {
          color: 'secondary',
          onClick: () => onSubmit(),
          isLoading: isDocumentUploading,
          disabled: !(document || (watch('documentView') === 'generate' && isPOExist)),
        },
      }}>
      <Container gap={4} className={'pb-4'}>
        <FormProvider {...methods}>
          <div>
            <Container gap={4}>
              <Typography variant={'headline_ss_s'}>Select document type</Typography>
              <Controller
                key={'documentType'}
                name={'documentType'}
                control={control}
                render={({ field, fieldState: { invalid } }) => (
                  <Select
                    {...field}
                    key={field.value}
                    value={field.value || ''}
                    options={DOCUMENT_TYPE_OPTIONS}
                    onValueChange={value => {
                      field.onChange(value)
                      resetField('documents')
                      resetField('documentView')
                    }}
                    hasError={invalid}
                  />
                )}
              />
            </Container>
            {isOthers && (
              <Controller
                key={'documentName'}
                name={'documentName'}
                control={control}
                render={({ field, fieldState: { invalid } }) => (
                  <Input {...field} label={{ text: 'Enter name' }} heightFit hasError={invalid} />
                )}
              />
            )}
          </div>
          {renderContent()}
        </FormProvider>
      </Container>
    </SidebarWrapper>
  )
}
