import { Button, ButtonProps, cn, Typography } from '@timberhubcom/forest'
import React from 'react'

import { SidebarActionsEnum, useSidebarReducer } from '@/providers'

type CTAType = {
  label: string
  props?: Omit<ButtonProps, 'variant'>
}

type SidebarWrapperProps = {
  children: React.ReactNode
  title: string
  className?: string
  primaryCTA?: CTAType
  secondaryCTA?: CTAType
}

export const SidebarWrapper = ({ children, title, primaryCTA, secondaryCTA, className }: SidebarWrapperProps) => {
  const { dispatch } = useSidebarReducer()

  const hasCTA = !!primaryCTA || !!secondaryCTA
  return (
    <div
      className={cn('sticky bottom-0 left-0 top-0 flex h-screen w-[413px] flex-shrink-0 flex-col bg-white', className)}>
      <div className={'mb-6 flex h-8 items-center justify-end pl-6 pr-2 pt-2'}>
        <Button
          variant={'icon'}
          size={'s'}
          icon={'X'}
          color={'grey'}
          onClick={() => dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'default' } })}
        />
      </div>
      <div className={'flex flex-grow flex-col gap-6 overflow-y-auto px-6'}>
        <Typography variant={'headline_l'} className={'mb-4'}>
          {title}
        </Typography>
        {children}
      </div>
      {hasCTA && (
        <div className={'flex items-center justify-center gap-2 border-t p-4'}>
          {primaryCTA && (
            <Button
              variant={'primary'}
              size={'l'}
              {...primaryCTA.props}
              className={cn(primaryCTA.props?.className, 'w-full')}>
              {primaryCTA.label}
            </Button>
          )}
          {secondaryCTA && (
            <Button
              variant={'primary'}
              size={'l'}
              {...secondaryCTA.props}
              className={cn(secondaryCTA.props?.className, 'w-full')}>
              {secondaryCTA.label}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
