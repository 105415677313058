const statuses = ['Purchased', 'Cancelled']
export const isConfirmedStatus = (status: string) => !statuses.includes(status)

export const companyDisplayUrl = (companyURL: string) => {
  const firstSplit = companyURL.split('/companies')[1] // Get the part after '/companies'
  const value = firstSplit.split('/edit')[0] // Get the part before '/edit'
  return `ops${value}` // Concatenate 'ops' with the extracted part
}

export const toLocalISOString = (dateStr: string | Date) => {
  const date = new Date(dateStr)
  const offsetMs = date.getTimezoneOffset() * 60 * 1000
  const localDate = new Date(date.getTime() - offsetMs)
  const localISO = localDate.toISOString().slice(0, 10)
  return localISO + 'T00:00:00.000' + getTimezoneOffsetString(date)
}

const getTimezoneOffsetString = (date: Date) => {
  const offset = date.getTimezoneOffset()
  const absOffset = Math.abs(offset)
  const hours = String(Math.floor(absOffset / 60)).padStart(2, '0')
  const minutes = String(absOffset % 60).padStart(2, '0')
  const sign = offset > 0 ? '-' : '+'
  return `${sign}${hours}:${minutes}`
}
