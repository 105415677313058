import { useSWRConfig } from 'swr'

export const useClearCache = () => {
  const { mutate, cache } = useSWRConfig()

  return {
    clearCache: async (pattern: RegExp | string) => {
      const cacheKeys = [...cache.keys()].filter(key => new RegExp(pattern).test(key))
      for (const key of cacheKeys) {
        await mutate(key)
      }
    },
  }
}
