import React from 'react'

import { useTruckReducer } from '@/providers'

import { SidebarWrapper } from '../../SidebarWrapper.tsx'
import { FormDap, FormExw } from './components'

export const SidebarStepViewSecond = () => {
  const { isEXW } = useTruckReducer()
  const ref: React.Ref<HTMLFormElement> = React.useRef(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onClick = () => {
    if (ref.current) {
      ref.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
    }
  }

  return (
    <SidebarWrapper
      title={'Update readiness'}
      primaryCTA={{
        label: 'Confirm readiness',
        props: {
          color: 'secondary',
          isLoading: isSubmitting,
          onClick,
        },
      }}>
      {isEXW ? (
        <FormExw ref={ref} setIsSubmitting={setIsSubmitting} />
      ) : (
        <FormDap ref={ref} setIsSubmitting={setIsSubmitting} />
      )}
    </SidebarWrapper>
  )
}
