import { Badge, Button, Collapsible, Container, Typography } from '@timberhubcom/forest'
import { Link } from 'react-router-dom'

import { useTruckReducer } from '@/providers'
import { getStatus, sanitizeId } from '@/utils'
import { truckStatusVariantMapper } from '@/utils/truckStatusVariantMapper'

export const CoPurchasedTrucksComponent = () => {
  const data = useTruckReducer()
  const relatedTrucks = data.related_trucks

  return (
    <Collapsible
      isDefaultOpened={relatedTrucks.length > 0}
      title={'Co-purchased trucks'}
      addonButtonProps={{
        children: 'Go to supplier quote',
        variant: 'primary',
        color: 'grey',
        url: data.urls.quote,
      }}>
      <Container gap={2}>
        <Container direction={'horizontal'} gap={6}>
          <div className={'w-1/2'}>
            <Typography variant={'body_s'}>Trucks</Typography>
          </div>
          <div className={'w-1/2'}>
            <Typography variant={'body_s'}>Supplier PO week</Typography>
          </div>
        </Container>
        <Container gap={1}>
          {relatedTrucks.length ? (
            relatedTrucks.map(truck => {
              const feStatus = getStatus(truck?.status, truck.order_truck_status)
              return (
                <Container key={truck.id} className={'flex flex-row items-center'} gap={6} direction={'horizontal'}>
                  <Container className={'w-1/2'} gap={1} direction={'horizontal'}>
                    <Typography variant={'headline_ss_xs'}>Truck {truck.truck_id}</Typography>
                    {feStatus && (
                      <Badge variant={truckStatusVariantMapper(feStatus)} className={'capitalize'}>
                        {feStatus}
                      </Badge>
                    )}
                  </Container>
                  <Container className={'w-1/2 justify-between'} direction={'horizontal'} gap={2}>
                    <Typography variant={'body_m'} className={'text-grey-overlay-900'}>
                      {truck.purchase_order_week}
                    </Typography>
                    <Link to={`/${sanitizeId(truck.truck_id)}`}>
                      <Button color={'grey'} variant={'icon'} size={'xs'} icon={'ArrowRight'} />
                    </Link>
                  </Container>
                </Container>
              )
            })
          ) : (
            <Typography variant={'body_s'} className={'py-6 text-center'}>
              No related trucks
            </Typography>
          )}
        </Container>
      </Container>
    </Collapsible>
  )
}
