export const downloadDocumentURL = async (url: string) => {
  // Create a temporary anchor (<a>) element
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank') // Open in a new tab
  a.setAttribute('rel', 'noopener noreferrer') // Avoids potential security risks
  document.body.appendChild(a)
  a.click()
  // Clean up the temporary anchor element
  document.body.removeChild(a)
}
