import { addWeeks, endOfWeek, getWeek, getYear, startOfWeek, subWeeks } from 'date-fns'

export const generateWeeks = (numberOfWeeks: number = 15, withPrevious?: boolean) => {
  const date = withPrevious ? subWeeks(new Date(), numberOfWeeks) : new Date()

  return [...Array(withPrevious ? numberOfWeeks * 2 : numberOfWeeks)].map((_, index) => {
    const newDate = addWeeks(date, index)

    const year = getYear(newDate)
    const week = getWeek(newDate)
    const startOfWeekDate = startOfWeek(newDate, { weekStartsOn: 1 })
    const endDateOfWeekDate = endOfWeek(newDate, { weekStartsOn: 1 })

    return {
      week,
      year,
      label: `W${week}, ${year}`,
      range: `${startOfWeekDate}-${endDateOfWeekDate}, ${year}`,
    }
  })
}
