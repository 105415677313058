import { Collapsible, Container, formatCurrency, Icon, Typography } from '@timberhubcom/forest'

import { useTruckReducer } from '@/providers'

export const DimensionsComponent = () => {
  const data = useTruckReducer()
  const products = data.products

  return (
    <Container gap={6}>
      {products.map(product => {
        return (
          <Collapsible
            key={product.id}
            isDefaultOpened={product.dimensions.length > 0}
            title={'Volume & purchased prices'}
            subtitle={
              <div>
                <Typography variant={'body_m'}>
                  <span className={'text-black'}>For: </span>
                  <span>{product.title}</span>
                </Typography>
              </div>
            }
            addonButtonProps={{
              children: 'Go to quote',
              variant: 'primary',
              color: 'grey',
              url: data.urls.quote,
            }}>
            <div className={'mb-4 flex flex-row'}>
              <div className={'w-1/2'}>
                <Typography variant={'body_s'}>Dimensions</Typography>
              </div>
              <div className={'w-1/3 grow-0'}>
                <Typography variant={'body_s'}>Volume</Typography>
              </div>
              <div className={'w-1/5 grow-0'}>
                <Typography variant={'body_s'}>Price</Typography>
              </div>
            </div>
            {product.dimensions.map((dimension, index) => {
              return (
                <div key={`${dimension.dimensions}_${index}`} className={'pb-1'}>
                  {/*dimension*/}
                  <div className={'flex flex-row'}>
                    <div className={'w-1/2'}>
                      <Typography variant={'headline_ss_xs'}>{dimension.dimensions}</Typography>
                    </div>
                    <div className={'w-1/3 grow-0'}>
                      <Typography variant={'body_m'} className={'!text-black'}>
                        {dimension.volume}m3
                      </Typography>
                    </div>
                    <div className={'w-1/5 grow-0'}>
                      <Typography variant={'headline_ss_xs'}>
                        {formatCurrency(dimension.price_per_cbm, dimension.currency)}/m3
                      </Typography>
                    </div>
                  </div>
                  {dimension.notes && (
                    <div className={'flex w-full flex-row items-center gap-x-1 bg-grey-50 px-2 py-1'}>
                      <Icon name={'ArrowElbowDownRight'} />
                      <Typography variant={'body_s'}>
                        <span className={'text-grey-overlay-900'}>Supplier: </span>
                        {dimension.notes}
                      </Typography>
                    </div>
                  )}
                </div>
              )
            })}
          </Collapsible>
        )
      })}
    </Container>
  )
}
