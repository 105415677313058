import {
  Button,
  Container,
  DocumentCard,
  Dropzone,
  DropzoneFile,
  Icon,
  TabsSwitch,
  Textarea,
  Typography,
} from '@timberhubcom/forest'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { DeleteDialog } from '@/components/common/DeleteDialog'
import { useTruckById } from '@/hooks'
import { useTruckReducer } from '@/providers'
import { downloadDocumentURL } from '@/utils/downloadDocumentURL'

export interface AddPurchaseOrderHandle {
  uploadFiles: () => Promise<void>
}

export const AddPurchaseOrder = forwardRef<AddPurchaseOrderHandle>((_, ref) => {
  const { documents, truck_id } = useTruckReducer()

  const POdocument = documents.find(item => item.machine_name === 'contract')
  const isPOExist = POdocument?.url

  const { uploadDocument } = useTruckById(truck_id)

  const { generatePurchaseOrder, isGeneratingPurchaseOrder } = useTruckById(truck_id)

  const tabSwitchData = {
    left: {
      text: 'Upload PO',
      value: 'upload_po',
    },
    right: {
      text: 'Generate PO',
      value: 'generate_po',
    },
  }

  const [value, setValue] = useState<string | number | boolean>(tabSwitchData.right.value)
  const [remarks, setRemarks] = useState('')
  const [files, setFiles] = React.useState<DropzoneFile[] | undefined>(undefined)
  const [fileList, setFileList] = React.useState<FileList | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemarks(event.target.value)
  }

  const generatePO = async () => {
    await generatePurchaseOrder({ id: truck_id, remarks })
  }

  useImperativeHandle(ref, () => ({
    async uploadFiles() {
      if (fileList && fileList.length > 0) {
        await uploadDocument({ id: truck_id, files: [...fileList], machineName: 'contract' })
          .then(() => setFiles(undefined))
          .finally(() => {
            setFiles(undefined)
            setFileList(null)
          })
      }
    },
  }))

  return (
    <div>
      <div>
        {/*start ready*/}
        {isPOExist && (
          <div>
            <DocumentCard
              addonEnd={
                <React.Fragment>
                  <DeleteDialog
                    id={POdocument.id}
                    title={'Delete PO & restart step'}
                    description={'Are you sure? You will restart the step from the beginning'}
                  />
                  <Button
                    color={'grey'}
                    icon={'DownloadSimple'}
                    iconWeight={'fill'}
                    variant={'icon'}
                    onClick={() => downloadDocumentURL(POdocument.url ?? '')}
                  />
                </React.Fragment>
              }
              title={POdocument.name}
              icon={{
                className: 'bg-shade fill-primary-400',
                name: 'Files',
              }}
              fileName={POdocument.url?.split('/').slice(-1)[0] ?? POdocument.name}
            />
          </div>
        )}
        {/*end ready */}
        {/*start in progress*/}
        {!isPOExist && (
          <div>
            <div className={'flex flex-col gap-y-6'}>
              {!files && (
                <TabsSwitch value={value} left={tabSwitchData.left} right={tabSwitchData.right} onChange={setValue} />
              )}
              {value === tabSwitchData.right.value ? (
                <div>
                  <Textarea
                    label={{
                      text: 'Enter notes',
                    }}
                    placeholder={'notes'}
                    value={remarks}
                    onChange={handleChange}
                    disabled={isGeneratingPurchaseOrder}
                  />
                  <Button
                    variant={'primary'}
                    isLoading={isGeneratingPurchaseOrder}
                    color={'secondary'}
                    className={'w-full'}
                    onClick={generatePO}>
                    Generate
                  </Button>
                </div>
              ) : (
                <div>
                  <Dropzone
                    files={files}
                    label={
                      <Container gap={4} className={'items-center justify-center text-center'}>
                        <div className={'flex size-10 items-center justify-center rounded-full bg-beech-100'}>
                          <Icon name={'FilePlus'} size={24} weight={'fill'} className={'fill-primary-400'} />
                        </div>
                        <Container gap={1}>
                          <Typography variant={'headline_ss_xxs'}>Drop files here to upload or</Typography>
                          <Typography variant={'headline_ss_xs'} className={'text-primary-400'}>
                            Browse files
                          </Typography>
                        </Container>
                      </Container>
                    }
                    onDelete={() => setFiles(undefined)}
                    onChange={async dropFiles => {
                      setFiles(
                        dropFiles
                          ? [...dropFiles].map(file => ({
                              ...file,
                              title: 'Purchase order',
                              name: file.name,
                              URL: file.webkitRelativePath,
                            }))
                          : undefined
                      )

                      setFileList(dropFiles)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {/*end in progress*/}
      </div>
    </div>
  )
})
