import { Container } from '@timberhubcom/forest'
import { useParams } from 'react-router-dom'

import {
  CoPurchasedTrucksComponent,
  DimensionsComponent,
  DocumentsComponent,
  NotesComponent,
  OverviewComponent,
  SidebarComponent,
  SoldWithComponent,
  TimelineComponent,
} from '@/components'
import { SidebarProvider, TruckProvider } from '@/providers'

export const TruckDetailPage = () => {
  const { id } = useParams()
  const changeId = id?.replace('/', '-')

  return (
    <TruckProvider id={`${changeId}`}>
      <div className={'bg-gray-100 flex min-h-screen w-full flex-row gap-8'}>
        <SidebarProvider>
          <SidebarComponent />
          <div className={'container mx-auto flex w-full max-w-screen-2xl py-10 pr-9'}>
            <Container gap={6} className={'w-full'}>
              {/*overview block*/}
              <OverviewComponent />
              {/*notes / "chat" */}
              <NotesComponent />
              {/*timeline*/}
              <TimelineComponent />
              {/*Volume & purchased prices*/}
              <DimensionsComponent />
              {/*sold with*/}
              <SoldWithComponent />
              {/*documents*/}
              <DocumentsComponent />
              {/*Co-purchased trucks*/}
              <CoPurchasedTrucksComponent />
            </Container>
          </div>
        </SidebarProvider>
      </div>
    </TruckProvider>
  )
}
