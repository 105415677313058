import { Typography } from '@timberhubcom/forest'
import { AxiosError } from 'axios'
import React from 'react'

const processErrorMessage = (errorMessage?: string) => {
  if (errorMessage?.toLowerCase().includes('no query results')) {
    return 'Invalid URL. Check the URL and make sure it is a correct purchased truck ID or that the truck is purchased. \n Thank you 😉'
      .split('\n')
      .map((text, index) => (
        <React.Fragment key={index}>
          {text}
          <br />
        </React.Fragment>
      ))
  }

  return errorMessage ?? 'Whoops, something went wrong on our servers.'
}

export const ServerError = ({ error }: { error: AxiosError<{ message: string }> }) => {
  const statusCode = error.response?.status
  const statusText = error.response?.statusText ?? 'Server Error'
  const errorMessage = processErrorMessage(error.response?.data?.message)
  return (
    <div
      className={
        'flex h-screen w-full flex-col items-center justify-center space-x-8 space-y-16 lg:flex-row lg:space-y-0 2xl:space-x-0'
      }>
      <div className={'flex w-full flex-col items-center justify-center text-center lg:w-1/2 lg:px-2 xl:px-0'}>
        <Typography variant={'headline_xxxl'}>{statusCode}</Typography>
        <Typography variant={'headline_xxl'}>{statusText}</Typography>
        <Typography variant={'headline_ss_m'} className={'text-grey-overlay-700'}>
          {errorMessage}
        </Typography>
      </div>
    </div>
  )
}
