import { Button, Container, Dropzone, Icon, Textarea, Typography } from '@timberhubcom/forest'
import { Controller, useFormContext } from 'react-hook-form'

import { useTruckReducer } from '@/providers'

import { SidebarDocumentsFormData } from '../sidebardocumentForm'
import { documentTypeOptions } from '../sidebarDocumentTypeOptions'

type SidebarDocumentUploadViewProps = {
  isUploadView: boolean
  isGenerating: boolean
  onGenerate: () => void
}
export const SidebarDocumentUploadView = ({
  isUploadView,
  isGenerating,
  onGenerate,
}: SidebarDocumentUploadViewProps) => {
  const { control, watch } = useFormContext<SidebarDocumentsFormData>()
  const { has_order_truck } = useTruckReducer()

  const documentType = watch('documentType')

  const getDocumentTitle = () => {
    if (documentType === 'other_document') {
      return watch('documentName') || 'Others'
    }
    return documentTypeOptions(has_order_truck).find(item => item.value === documentType)?.label ?? ''
  }

  return isUploadView ? (
    <Controller
      key={'documents'}
      name={'documents'}
      control={control}
      render={({ field }) => (
        <Dropzone
          key={documentType}
          multiple={documentType === 'supplier_proforma'}
          files={
            field.value.length > 0
              ? [...field.value].map(document => ({
                  ...document,
                  name: document.name,
                  title: getDocumentTitle(),
                  URL: URL.createObjectURL(document) || document.webkitRelativePath,
                }))
              : []
          }
          label={
            <Container gap={4} className={'items-center justify-center text-center'}>
              <div className={'flex size-10 items-center justify-center rounded-full bg-beech-100'}>
                <Icon name={'FilePlus'} size={24} weight={'fill'} className={'fill-primary-400'} />
              </div>
              <Container gap={1}>
                <Typography variant={'headline_ss_xxs'}>Drop files here to upload or</Typography>
                <Typography variant={'headline_ss_xs'} className={'text-primary-400'}>
                  Browse files
                </Typography>
              </Container>
            </Container>
          }
          onDelete={() => field.onChange([])}
          onChange={async dropFiles => {
            field.onChange(dropFiles ?? [])
          }}
        />
      )}
    />
  ) : (
    <Container gap={4}>
      <Controller
        key={'remarks'}
        name={'remarks'}
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            label={{
              text: 'Enter notes',
            }}
            placeholder={'Notes to be in the document goes here'}
            disabled={isGenerating}
            rows={5}
            heightFit
          />
        )}
      />

      <Button
        variant={'primary'}
        isLoading={isGenerating}
        color={'secondary'}
        className={'w-full'}
        onClick={onGenerate}>
        Generate
      </Button>
    </Container>
  )
}
