export enum OrderTruckStatusEnum {
  PURCHASED = 'purchased',
  CONFIRMED = 'confirmed',
  PREBOOKED = 'prebooked',
  BOOKED = 'booked',
  COLLECTED = 'collected',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}
