import {
  Button,
  DocumentCard,
  Dropzone,
  DropzoneFile,
  Icon,
  RadioButtonsGroup,
  Textarea,
  Typography,
} from '@timberhubcom/forest'
import React, { forwardRef, useImperativeHandle } from 'react'

import { DeleteDialog } from '@/components/common/DeleteDialog.tsx'
import { useTruckById } from '@/hooks'
import { useTruckReducer } from '@/providers'
import { downloadDocumentURL } from '@/utils/downloadDocumentURL.ts'

export interface UploadOrderConfirmationHandle {
  uploadFiles: () => Promise<void>
  confirmPurchase: () => Promise<void>
}

export const UploadOrderConfirmation = forwardRef<UploadOrderConfirmationHandle>((_, ref) => {
  const { totalNumberOfTrucks, documents, truck_id, supplier_reference_number, numberOfConfirmedTrucks } =
    useTruckReducer()
  const allOrSinglePreselect = documents.find(item => item.machine_name === 'supplier_proforma')?.trucks
  const { uploadDocument, confirmPurchase, updateTruck } = useTruckById(truck_id)
  const hideAllTrucksOption =
    (numberOfConfirmedTrucks > 0 && numberOfConfirmedTrucks <= totalNumberOfTrucks) || totalNumberOfTrucks === 1

  const radioOptions = [
    ...(!hideAllTrucksOption
      ? [
          {
            value: 'all',
            label: `All ${totalNumberOfTrucks} trucks`,
          },
        ]
      : []),
    {
      value: 'single',
      label: 'Only this truck',
    },
  ]

  const [files, setFiles] = React.useState<DropzoneFile[] | undefined>(undefined)
  const [additionalFiles] = React.useState<DropzoneFile[] | undefined>(undefined)
  const [fileList, setFileList] = React.useState<FileList | null>(null)
  const [radioButton, setRadioButton] = React.useState(() => {
    if (allOrSinglePreselect) {
      return allOrSinglePreselect
    }
    if (hideAllTrucksOption) {
      return 'single'
    }
    return 'all'
  })
  const [supplierContract, setSupplierContract] = React.useState(supplier_reference_number || '')

  const documentsList = documents.filter(item => item.machine_name === 'supplier_proforma')

  const removeItemByName = (name: string) => {
    if (files) {
      if (files.length > 1) {
        const updatedFiles = files.filter(file => file.name !== name)
        setFiles(updatedFiles)
      } else {
        setFiles(undefined)
      }
    }

    if (fileList) {
      const updatedFileList = new DataTransfer()
      if (fileList.length > 1) {
        Array.from(fileList).forEach(file => {
          if (file.name !== name) {
            updatedFileList.items.add(file)
          }
        })

        setFileList(updatedFileList.files)
      }
      setFileList(null)
    }
  }

  const documentsUpload = async () => {
    if (fileList && fileList.length > 0) {
      await uploadDocument({
        id: truck_id,
        files: [...fileList],
        machineName: 'supplier_proforma',
        trucks: radioButton,
      }).finally(() => {
        setFiles(undefined)
        setFileList(null)
      })
    }
  }

  const updateSupplierContractNumber = async () => {
    if (supplierContract !== supplier_reference_number) {
      await updateTruck({ supplier_reference_number: supplierContract })
    }
  }

  const addAdditionalFiles = (dropFiles: FileList | null) => {
    const mergedFiles: DropzoneFile[] = []
    const dataTransfer = new DataTransfer()

    // copy of existing files if there are any
    if (files && fileList) {
      for (let i = 0; i < fileList.length; i++) {
        dataTransfer.items.add(fileList[i])
      }
      mergedFiles.push(...files)
    }

    // adding files from second dropzone
    if (dropFiles) {
      const addFiles: DropzoneFile[] = [...dropFiles].map(file => ({
        ...file,
        title: file.name,
        name: file.name,
        URL: file.webkitRelativePath,
      }))
      mergedFiles.push(...addFiles)

      for (let i = 0; i < dropFiles.length; i++) {
        dataTransfer.items.add(dropFiles[i])
      }
    }

    setFiles(mergedFiles.length > 0 ? mergedFiles : undefined)
    setFileList(dataTransfer.files ? dataTransfer.files : null)
  }

  useImperativeHandle(ref, () => ({
    async uploadFiles() {
      await documentsUpload()
      await updateSupplierContractNumber()
    },
    async confirmPurchase() {
      await documentsUpload()
      await updateSupplierContractNumber()
      await confirmPurchase({ id: truck_id, trucks: radioButton })
    },
  }))

  return (
    <div>
      <div className={'flex flex-col gap-4'}>
        {/*start existing other documents*/}
        <div>
          {documentsList.map(document => {
            return (
              <DocumentCard
                key={document.id}
                addonEnd={
                  <React.Fragment>
                    <DeleteDialog id={document.id} title={`Delete ${document.name}`} />
                    <Button
                      color={'grey'}
                      icon={'DownloadSimple'}
                      iconWeight={'fill'}
                      variant={'icon'}
                      onClick={() => downloadDocumentURL(document.url ?? '')}
                    />
                  </React.Fragment>
                }
                title={document.name}
                icon={{
                  className: 'bg-shade fill-primary-400',
                  name: 'Files',
                }}
                fileName={document.url?.split('/').slice(-1)[0] ?? document.name}
              />
            )
          })}
        </div>
        {/*end existing other documents*/}
        <div>
          <Dropzone
            variant={'simple'}
            files={files}
            multiple={true}
            onDelete={evt => removeItemByName(evt.name)}
            onChange={async dropFiles => {
              setFiles(
                dropFiles
                  ? [...dropFiles].map(file => ({
                      ...file,
                      title: file.name,
                      name: file.name,
                      URL: file.webkitRelativePath,
                    }))
                  : undefined
              )

              setFileList(dropFiles)
            }}
            label={
              <div className={'flex items-center gap-2'}>
                <Icon name={'PlusCircle'} size={24} weight={'fill'} className={'fill-primary-400'} />
                <Typography variant={'headline_ss_xxs'}>Add document</Typography>
              </div>
            }
          />
          {files && files.length > 0 && (
            <Dropzone
              variant={'simple'}
              files={additionalFiles}
              multiple={true}
              onChange={async dropFiles => {
                addAdditionalFiles(dropFiles)
              }}
              label={
                <div className={'flex items-center gap-2'}>
                  <Icon name={'PlusCircle'} size={24} weight={'fill'} className={'fill-primary-400'} />
                  <Typography variant={'headline_ss_xxs'}>Add document</Typography>
                </div>
              }
            />
          )}
        </div>
        <div>
          <Typography variant={'headline_ss_xs'} className={'mb-2'}>
            Add supplier contract number (if any)
          </Typography>
          <Textarea
            placeholder={'Enter supplier contract number'}
            value={supplierContract}
            onChange={e => setSupplierContract(e.target.value)}
          />
        </div>
        <div>
          <Typography variant={'headline_ss_xs'} className={'mb-2'}>
            Confirm purchase & apply this step to:
          </Typography>
          <RadioButtonsGroup
            options={radioOptions}
            onValueChange={value => setRadioButton(value)}
            value={radioButton}
          />
        </div>
      </div>
    </div>
  )
})
