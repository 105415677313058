import { BlockContainer } from '@timberhubcom/forest'
import React, { useRef } from 'react'

import { TrucksStatusAlert } from '@/components/common/TrucksStatusAlert.tsx'
import {
  AddPurchaseOrder,
  AddPurchaseOrderHandle,
} from '@/components/Sidebar/SidebarViews/SidebarStepViewFirst/components/AddPurchaseOrder.tsx'
import { ReviewAndSendPO } from '@/components/Sidebar/SidebarViews/SidebarStepViewFirst/components/ReviewAndSendPO.tsx'
import {
  UploadOrderConfirmation,
  UploadOrderConfirmationHandle,
} from '@/components/Sidebar/SidebarViews/SidebarStepViewFirst/components/UploadOrderConfirmation.tsx'
import { isConfirmedStatus } from '@/lib/helpers.ts'
import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'

import { SidebarWrapper } from '../../SidebarWrapper.tsx'

export const SidebarStepViewFirst = () => {
  const { dispatch } = useSidebarReducer()
  const { purchase_order_sent, documents, fe_status } = useTruckReducer()
  const [isConfirming, setIsConfirming] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const POdocument = documents.find(item => item.machine_name === 'contract')
  const isPOExist = !!POdocument?.url

  const addPurchaseOrder = useRef<AddPurchaseOrderHandle>(null)
  const uploadOrderConfirmation = useRef<UploadOrderConfirmationHandle>(null)
  const isConfirmed = isConfirmedStatus(fe_status)

  // TODO: conditions or getting back on document deletion
  const triggerFileUpload = async () => {
    if (uploadOrderConfirmation.current && isPOExist && purchase_order_sent) {
      await uploadOrderConfirmation.current.uploadFiles()
    } else if (addPurchaseOrder.current) {
      await addPurchaseOrder.current.uploadFiles()
    }
  }

  const triggerConfirmPurchase = async () => {
    if (uploadOrderConfirmation.current) {
      await uploadOrderConfirmation.current.confirmPurchase()
    }
  }

  return (
    <SidebarWrapper
      title={'Confirm purchase'}
      primaryCTA={{
        label: 'Save & finish later',
        props: {
          disabled: isSaving,
          isLoading: isSaving,
          color: 'grey',
          onClick: async () => {
            setIsSaving(true)
            await triggerFileUpload()
            setIsSaving(false)
            dispatch({ type: SidebarActionsEnum.RESET })
          },
        },
      }}
      secondaryCTA={{
        label: 'Confirm purchase',
        props: {
          isLoading: isConfirming,
          color: 'grey',
          disabled: !isPOExist || !purchase_order_sent,
          onClick: async () => {
            setIsConfirming(true)
            await triggerConfirmPurchase()
            setIsConfirming(false)
            dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'default', step: 2, isConfirmed: true } })
          },
        },
      }}>
      <div className={'flex flex-col gap-12'}>
        {/*start of part 1 */}
        <TrucksStatusAlert />
        <BlockContainer
          title={'Add a purchase order'}
          step={{
            number: 1,
            isComplete: isPOExist,
            isPadded: false,
          }}>
          <AddPurchaseOrder ref={addPurchaseOrder} />
        </BlockContainer>

        {/*end of part 1 */}

        {/*start of part 2 */}
        <BlockContainer
          title={'Review & send PO'}
          step={{
            number: 2,
            isPadded: false,
            isComplete: purchase_order_sent,
          }}
          hideContent={!isPOExist}>
          {/*<ReviewAndSendPO onSubmit={() => setShowUpload(true)} />*/}
          <ReviewAndSendPO />
        </BlockContainer>
        {/*end of part 2 */}

        {/*start of part 3 */}
        <BlockContainer
          title={'Upload order confirmation'}
          hideContent={!isPOExist || !purchase_order_sent}
          step={{
            number: 3,
            isPadded: false,
            disabled: !isPOExist || !purchase_order_sent,
            isComplete: isConfirmed,
          }}>
          <UploadOrderConfirmation ref={uploadOrderConfirmation} />
        </BlockContainer>
        {/*end of part 3 */}
      </div>
    </SidebarWrapper>
  )
}
