import { BrandIcon, Button, Collapsible, Typography } from '@timberhubcom/forest'
import * as React from 'react'

import { useTruckReducer } from '@/providers'

const EllipseArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns={'http://www.w3.org/2000/svg'} width={24} height={8} fill={'none'} {...props}>
    <path stroke={'#212121'} strokeWidth={2} d={'M8 4h13'} />
    <path fill={'#212121'} d={'m24 4-4.5 3.464V.536L24 4Z'} />
    <circle cx={3} cy={4} r={3} fill={'#212121'} />
  </svg>
)

type TruckRowProps = {
  title: string
  value: React.ReactNode
}

const TruckDetails = ({ title, value }: TruckRowProps) => (
  <div className={'mb-2 flex'}>
    <div className={'w-1/2'}>
      <Typography variant={'headline_ss_xs'}>{title}</Typography>
    </div>
    <div className={'w-1/2'}>
      {typeof value === 'string' ? <Typography variant={'body_m'}>{value}</Typography> : value}
    </div>
  </div>
)

export const SoldWithComponent = () => {
  const data = useTruckReducer()
  const isDefaultOpened = !!data.proforma_date || !!data.delivery_address.company_name || !!data.order_id
  return (
    <Collapsible
      isDefaultOpened={isDefaultOpened}
      title={`Truck ${data.order_truck_id ?? 'N/A'}`}
      label={'Sold with'}
      icon={
        <div className={'relative grid size-12 place-content-center bg-grey-100 px-3 pb-3.5 pt-2'}>
          <BrandIcon color={'grey'} size={'s'} className={'size-6'} />
          <EllipseArrow className={'absolute bottom-1 right-1'} />
        </div>
      }
      addonButtonProps={
        data.has_order_truck
          ? {
              children: 'View',
              variant: 'primary',
              color: 'grey',
              url: data.urls.truck,
            }
          : undefined
      }>
      <div className={'flex flex-col'}>
        <TruckDetails title={'Proforma date'} value={data.proforma_date ?? '-'} />
        <TruckDetails title={'Customer'} value={data.delivery_address.company_name ?? '-'} />
        <TruckDetails
          title={'Order'}
          value={
            data.order_id ? (
              <a href={data.urls.order} target={'_blank'} rel={'noopener noreferrer'}>
                <Button variant={'tertiary'} className={'text_link_m underline'}>
                  {data.urls.order.split('/').pop()}
                </Button>
              </a>
            ) : (
              '-'
            )
          }
        />
      </div>
    </Collapsible>
  )
}
