import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useState } from 'react'

export function useToggle(defaultValue?: boolean): {
  value: boolean
  toggle: () => void
  setValue: Dispatch<SetStateAction<boolean>>
} {
  const [value, setValue] = useState(!!defaultValue)

  const toggle = useCallback(() => {
    setValue(x => !x)
  }, [])

  return { value, toggle, setValue }
}
