import {
  Badge,
  Button,
  cn,
  Collapsible,
  Container,
  Dialog,
  Icon,
  IconName,
  Separator,
  Textarea,
  Typography,
} from '@timberhubcom/forest'
import { nanoid } from 'nanoid'
import React from 'react'

import { useTruckById } from '@/hooks'
import { PurchaseTruckNote, PurchaseTruckNoteType } from '@/models/truck.model'
import { useTruckReducer } from '@/providers'

type NoteIconVariant = 'info' | 'primary' | 'grey'

const iconColors = new Map<NoteIconVariant, [string, string]>([
  ['info', ['bg-info-100', 'fill-info-400']],
  ['primary', ['bg-beech-100', 'fill-primary-400']],
  ['grey', ['bg-grey-200', 'fill-grey-overlay-900']],
])

const NoteIcon = ({ variant, icon }: { variant: NoteIconVariant; icon: IconName }) => {
  const [backgroundColor, fillColor] = iconColors.get(variant) ?? (iconColors.get('info') as [string, string])

  return (
    <div className={cn('grid size-8 place-items-center rounded-full', backgroundColor)}>
      <Icon name={icon} className={fillColor} weight={'fill'} />
    </div>
  )
}

const groupNotes = (notes: PurchaseTruckNote[]) =>
  notes.reduce(
    (acc, note) => {
      if (!acc[note.type]) {
        acc[note.type] = []
      }
      acc[note.type].push(note)
      return acc
    },
    {} as Record<PurchaseTruckNoteType, PurchaseTruckNote[]>
  )

type CollapsibleNoteProps = {
  children: React.ReactNode
  title: string
  icon: IconName
  variant: NoteIconVariant
  isOpen: boolean
  numberOfMessages?: number
  fallbackText?: string
}
const CollapsibleNote = ({
  children,
  title,
  icon,
  variant,
  isOpen,
  numberOfMessages = 0,
  fallbackText = 'No notes',
}: CollapsibleNoteProps) => {
  const [isNoteOpen, setIsNoteOpen] = React.useState(isOpen)

  return (
    <Collapsible
      isOpen={isNoteOpen}
      onValueChange={val => setIsNoteOpen(val)}
      key={title + isNoteOpen}
      title={
        <Container gap={2} direction={'horizontal'}>
          <Typography variant={'headline_ss_xs'}>{title}</Typography>
          {!!numberOfMessages && !isNoteOpen && (
            <Badge variant={variant}>{numberOfMessages > 1 ? `${numberOfMessages} messages` : `1 message`}</Badge>
          )}
        </Container>
      }
      icon={<NoteIcon variant={variant} icon={icon} />}
      headerClassName={cn({ '!border-b-0': isNoteOpen })}
      className={'!pl-20 !pt-0'}
      dropdownButton={{
        variant: 'icon',
        icon: 'CaretDown',
        className: 'bg-transparent',
      }}
      containerClassName={'border-0'}>
      <Container gap={4}>
        {numberOfMessages ? (
          children
        ) : (
          <Typography variant={'body_s'} className={'text-center text-grey-overlay-900'}>
            {fallbackText}
          </Typography>
        )}
      </Container>
    </Collapsible>
  )
}

const getNoteSource = (noteSource: string) =>
  noteSource === 'supplier_quote' ? 'Supplier quote' : noteSource?.replace(/_/g, ' ')

export const NotesComponent = () => {
  const { notes: data, truck_id } = useTruckReducer()
  const { updateTruck } = useTruckById(truck_id)

  const [isNotesOpen, setIsNotesOpen] = React.useState(false)

  const {
    customer: customerNotes = [],
    supplier: supplierNotes = [],
    internal_sales_ops: internalSalesOpsNotes = [],
    internal: internalNotes = [],
    internal_purchase: internalPurchaseNotes = [],
    external_purchase: externalPurchaseNotes = [],
  } = groupNotes(data)
  const hasNotes =
    customerNotes.length > 0 ||
    supplierNotes.length > 0 ||
    internalSalesOpsNotes.length > 0 ||
    internalNotes.length > 0 ||
    internalPurchaseNotes.length > 0 ||
    externalPurchaseNotes.length > 0
  const allInternalNotes = [
    ...internalSalesOpsNotes,
    ...internalPurchaseNotes,
    ...internalNotes,
    ...externalPurchaseNotes,
  ]

  const defaultPurchasedTruckInternalNote =
    internalNotes.find(({ externalVisibility, source }) => !externalVisibility && source === 'purchase_order_truck')
      ?.note ?? ''

  const defaultPurchasedTruckExternalNote =
    internalNotes.find(({ externalVisibility, source }) => {
      return externalVisibility && source === 'purchase_order_truck'
    })?.note ?? ''

  const [internalNote, setInternalNote] = React.useState(defaultPurchasedTruckInternalNote)
  const [externalNote, setExternalNote] = React.useState(defaultPurchasedTruckExternalNote)

  return (
    <Collapsible
      isDefaultOpened={hasNotes}
      className={'[button_h5]:!headline_ss_xs !p-0'}
      title={'Communications'}
      addonButtonElement={
        <div onClick={e => e.stopPropagation()}>
          <Dialog
            title={defaultPurchasedTruckInternalNote || defaultPurchasedTruckExternalNote ? 'Edit note' : 'Add note'}
            confirm={{
              label: 'Save',
              color: 'secondary',
              onClick: async () => {
                await updateTruck({
                  internal_notes: internalNote,
                  external_notes: externalNote,
                })
                setIsNotesOpen(false)
              },
            }}
            trigger={
              <Button
                color={'grey'}
                variant={'primary'}
                size={'s'}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setIsNotesOpen(true)
                }}>
                {defaultPurchasedTruckInternalNote || defaultPurchasedTruckExternalNote ? 'Edit note' : 'Add note'}
              </Button>
            }
            open={isNotesOpen}
            onOpenChange={setIsNotesOpen}>
            <div className={'w-full min-w-80 gap-y-12'}>
              <Textarea
                label={{ text: 'Purchase truck notes' }}
                rows={4}
                value={internalNote}
                onChange={e => setInternalNote(e.target.value)}
              />

              <Textarea
                label={{ text: 'External Purchase truck notes' }}
                rows={3}
                value={externalNote}
                onChange={e => setExternalNote(e.target.value)}
              />
            </div>
          </Dialog>
        </div>
      }>
      {/* internal */}
      <CollapsibleNote
        title={'From internal'}
        isOpen={allInternalNotes.length > 0}
        variant={'grey'}
        icon={'GearSix'}
        numberOfMessages={allInternalNotes.length}>
        {allInternalNotes.map(note => {
          const isSupplierNote = note.visibleTo === 'supplier' && note.externalVisibility

          return (
            <Container gap={6} key={nanoid()} direction={'horizontal'} className={'items-start justify-between'}>
              <Container gap={1}>
                <Typography variant={'body_s'} className={'whitespace-pre-line text-grey-overlay-900'}>
                  “{note.note}”
                </Typography>
                <Typography
                  variant={'caption'}
                  className={'font-semibold text-grey-overlay-900 first-letter:uppercase'}>
                  {getNoteSource(note.source)}
                </Typography>
              </Container>
              {note.externalVisibility && (
                <Badge variant={isSupplierNote ? 'info' : 'primary'} className={'flex-shrink-0'}>
                  Visible to {note.visibleTo === 'buyer' ? 'Customer' : 'Supplier'}
                </Badge>
              )}
            </Container>
          )
        })}
      </CollapsibleNote>

      <Separator />

      {/* customer */}
      <CollapsibleNote
        title={'From customer'}
        isOpen={customerNotes.length > 0}
        variant={'info'}
        icon={'HouseLine'}
        numberOfMessages={customerNotes.length}
        fallbackText={'No notes from customer'}>
        {customerNotes.map(note => (
          <Container gap={1} key={nanoid()}>
            <Typography variant={'body_s'} className={'text-grey-overlay-900'}>
              “{note.note}”
            </Typography>
            <p className={'caption font-semibold text-grey-overlay-900 first-letter:uppercase'}>
              {getNoteSource(note.source)}
            </p>
          </Container>
        ))}
      </CollapsibleNote>

      <Separator />

      {/* supplier */}
      <CollapsibleNote
        title={'From supplier'}
        isOpen={supplierNotes.length > 0}
        variant={'primary'}
        icon={'TreeEvergreen'}
        numberOfMessages={supplierNotes.length}
        fallbackText={' No notes from supplier'}>
        {supplierNotes.map(note => (
          <Container gap={1} key={nanoid()}>
            <Typography variant={'body_s'} className={'text-grey-overlay-900'}>
              “{note.note}”
            </Typography>
            <p className={'caption font-semibold text-grey-overlay-900 first-letter:uppercase'}>
              {getNoteSource(note.source)}
            </p>
          </Container>
        ))}
      </CollapsibleNote>
    </Collapsible>
  )
}
