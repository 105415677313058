import { ToastProvider } from '@timberhubcom/forest'
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { TruckDetailPage } from '@/pages'

function App() {
  return (
    <React.Fragment>
      <ToastProvider richColors position={'bottom-left'} />
      <Router>
        <Routes>
          <Route path={'/:id'} element={<TruckDetailPage />} />
        </Routes>
      </Router>
    </React.Fragment>
  )
}

export default App
