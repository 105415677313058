import { Button, Container, Dialog, Icon, Typography } from '@timberhubcom/forest'
import React from 'react'

import { useTruckById } from '@/hooks'
import { useTruckReducer } from '@/providers'

type DeleteDialogProps = {
  title?: string
  description?: string
  id?: string
}
export const DeleteDialog = ({
  title = 'Delete purchase order',
  description = 'Are you sure? This action is permanent and irreversible.',
  id,
}: DeleteDialogProps) => {
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)

  const { truck_id } = useTruckReducer()
  const { deleteTruckDocument, isDeletingTruckDocument } = useTruckById(truck_id)

  const handleSubmit = async () => {
    if (id) {
      await deleteTruckDocument(id)
    }
    setIsDeleteOpen(false)
  }

  return (
    <Dialog
      title={''}
      confirm={{
        label: 'Yes, delete',
        color: 'secondary',
        disabled: isDeletingTruckDocument,
        onClick: handleSubmit,
      }}
      cancelLabel={'No, cancel'}
      trigger={<Button variant={'icon'} icon={'TrashSimple'} color={'error'} iconWeight={'fill'} />}
      className={'!max-w-80'}
      open={isDeleteOpen}
      onOpenChange={setIsDeleteOpen}>
      <Container gap={4} className={'mb-4'}>
        <div className={'mx-auto flex size-10 items-center justify-center rounded-full bg-warning-100'}>
          <Icon name={'WarningCircle'} size={24} weight={'fill'} className={'text-warning-400'} />
        </div>
        <Container gap={1} className={'text-center'}>
          <Typography variant={'headline_ss_xs'}>{title}</Typography>
          <Typography variant={'body_s'}>{description}</Typography>
        </Container>
      </Container>
    </Dialog>
  )
}
