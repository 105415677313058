import { Button, Container, Typography } from '@timberhubcom/forest'

import { SidebarActionsEnum, useSidebarReducer, useTruckReducer } from '@/providers'

import { StepProgressBar } from './components/StepProgressBar'

export type Step = 1 | 2 | 3 | 4

const getStepTitle = (step: Step, isConfirmed = false, isReadinessConfirmed = false) => {
  switch (step) {
    case 1:
      return isConfirmed ? 'Purchase confirmed' : 'Confirm purchase'
    case 2:
      return isReadinessConfirmed ? 'Readiness confirmed' : 'Confirm readiness'
    case 3:
      return 'Confirm step 3'
    case 4:
      return 'Confirm step 4'
  }
}

export const SidebarSteps = () => {
  const {
    state: { step, isConfirmed },
    dispatch,
  } = useSidebarReducer()
  const { readiness_date, isReadinessConfirmed, allStepsCompleted } = useTruckReducer()

  const getStepSubtitle = () => {
    switch (step) {
      case 1:
        return isConfirmed ? 'Purchase confirmed' : 'Purchase placed'
      case 2:
        if (isReadinessConfirmed) {
          return `Readiness confirmed`
        }

        return readiness_date?.type === 'week' && readiness_date?.date
          ? `Readiness Week: ${readiness_date?.date}`
          : readiness_date?.date
            ? `Readiness Date: ${readiness_date?.date}`
            : ''
      case 3:
        return 'Confirm step 3'
      case 4:
        return 'Confirm step 4'
    }
  }

  const showCTA = !((isConfirmed && step === 1) || (isReadinessConfirmed && step === 2)) && !allStepsCompleted

  return (
    <Container gap={2} className={'px-6'}>
      <StepProgressBar totalSteps={4} currentStep={step} />

      <Container direction={'horizontal'} className={'w-full justify-between'}>
        <Button
          variant={'icon'}
          icon={'CaretLeft'}
          color={'grey'}
          className={'flex-shrink-0'}
          disabled={step === 1}
          onClick={() => dispatch({ type: SidebarActionsEnum.PREVIOUS })}
        />
        <Container gap={6} className={'items-center text-center'}>
          <Container gap={1} className={'text-center'}>
            <Typography variant={'headline_s'}>Step {step}/4</Typography>
            <Typography variant={'headline_ss_m'}>{getStepTitle(step, isConfirmed, isReadinessConfirmed)}</Typography>
            <Typography variant={'body_s'}>{getStepSubtitle()}</Typography>
          </Container>
          {showCTA && (
            <Button
              variant={'primary'}
              color={'secondary'}
              className={'w-fit'}
              onClick={() => dispatch({ type: SidebarActionsEnum.UPDATE, payload: { view: 'steps' } })}>
              Confirm
            </Button>
          )}
        </Container>
        <Button
          variant={'icon'}
          icon={'CaretRight'}
          color={'grey'}
          className={'flex-shrink-0'}
          disabled={step === 4}
          onClick={() => dispatch({ type: SidebarActionsEnum.NEXT })}
        />
      </Container>
    </Container>
  )
}
